import { colors } from '../../../style/variables'
import styled from 'styled-components'
import { UserRole } from '../../../interfaces/IWidgetInfo'

export const ButtonPulseStyled = styled.button`
    align-items: center;
    background: ${({ voted }) => (voted ? colors.darkGreyEgg : colors.white)};
    border-radius: 12px;
    border: none;
    box-shadow: 0px 0px 20px #00375014;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: bold;
    gap: 10px;
    height: 40px;
    justify-content: end;
    outline: none;
    padding: 0 14px 0 14px;
    position: ${({ expanded }) => (expanded ? 'relative' : 'absolute')};
    right: 0px;
    transition: all 400ms ease-in-out;
    white-space: nowrap;
    width: ${({ expanded }) => (expanded ? '145px' : '40px')};

    &:hover {
        background-color: ${({ expanded, voted }) => (expanded && !voted ? colors.perl : 'none')};
        width: 145px;

        .text {
            opacity: 1;
            transition: all 0.2s linear, opacity 0.5s linear;
            visibility: visible;
        }

        &::after {
            content: '';
            position: absolute;
            height: 40px;
            width: 22px;
            background: ${({ role }) =>
                role === UserRole.STUDENT || role === UserRole.FACILITATOR
                    ? 'transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 100%) 0% 0% no-repeat padding-box'
                    : 'none'};
            left: -22px;
            top: 0;
        }
    }

    .text {
        transition: all 0.2s linear, opacity 0.3s linear;
        visibility: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};
        opacity: ${({ expanded }) => (expanded ? 1 : 0)};
        color: ${({ voted }) => (voted ? colors.white : colors.darkGreyEgg)};
    }
`
