import { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../style/variables'

type TooltipProps = {
    content: string
    direction: 'top' | 'bottom' | 'left' | 'right'
    delay: number
    children?: JSX.Element
    showContentDirection?: 'center' | 'left' | 'right'
}

const Tooltip: React.FC<TooltipProps> = ({ content, direction = 'bottom', delay = 300, children, showContentDirection = 'center' }) => {
    let timeout: number
    const [show, setShow] = useState<boolean>(true)

    const showTip = () => {
        setTimeout(() => {
            setShow(true)
        }, delay)
    }

    const hideTip = () => {
        clearInterval(timeout)
        setShow(true)
    }

    return (
        <TooltipContainer onMouseEnter={showTip} onMouseLeave={hideTip}>
            {children}
            {show && showContentDirection === 'center' && <TooltipContent direction={direction}>{content}</TooltipContent>}
            {show && showContentDirection === 'left' && <TooltipContentLeft direction={direction}>{content}</TooltipContentLeft>}
        </TooltipContainer>
    )
}

export default Tooltip

const TooltipContainer = styled.div`
    display: inline-block;
    position: absolute;
    top: 45px;
    left: calc(50% - 50px);
`

const TooltipContent = styled.div<{ direction: 'top' | 'bottom' | 'left' | 'right'; toEnd: boolean }>`
    text-align: center;
    position: absolute;
    font-size: 12px;
    width: auto;
    min-width: 100px;
    min-height: 20px; /*min height of DIV should be set to at least 2x the width of the arrow*/
    background-color: ${colors.darkGreyEgg};
    color: white;
    padding: 6px;
    left: 0;
    word-wrap: break-word;
    -moz-border-radius: 5px; /*add some nice CSS3 round corners*/
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 2em;
    z-index: 99;
    border: 1px solid #1a506c;
    :after {
        content: '';
        display: block;
        position: absolute;
        top: -10px; /*should be set to -border-width x 2 */
        left: 45%;
        width: 0;
        height: 0;
        border-color: transparent transparent ${colors.darkGreyEgg} transparent; /*border color should be same as div div background color*/
        border-style: solid;
        border-width: 5px;
    }
`
const TooltipContentLeft = styled(TooltipContent)`
    left: -30px;
    :after {
        left: 76%;
    }
`
